<template>
    <div>
        <ul class="listUl">
            <li class="lisTitle">
                <span class="titleItem titleNum">编号</span>
                <span class="titleItem titleT">报告标题</span>
                <span class="titleItem titleTime">创建时间</span>
                <span class="titleItem titleType">报告类型</span>
                <span class="titleItem titleDo">操作</span>
            </li>
            <li class="listClass" v-for="vo in dataList" :key="vo.id">
                <span class="listItem listNum">{{vo.sn}}</span>
                <span class="listItem listT" @click="reDetail(vo.id)">{{vo.title}}</span>
                <span class="listItem listTime">{{vo.create_time}}</span>
                <span
                    class="listItem listType"
                    :style="vo.terms_type&&vo.terms_type == '3'?'color:#EB7846':vo.terms_type == '1'?'color:#F95E70':vo.terms_type == '2'?'color:#4F65E1':''"
                >{{terms_name[vo.terms_type]}}</span>
                <span class="listItem listDo">
                    <span class="seeDeIcon" @click="reDetail(vo.id)">查看报告</span>
                    <span class="deleteIcon" v-show="vo.type != 1" @click="delete_one(vo.id)">删除</span>
                </span>
            </li>
            <div class="noData" v-show="!dataList||dataList.length == 0">
                <img src="../../../assets/img/noMessage.png" class="noDataImg" />
                <p class="noDatap1">暂时没有报告</p>
                <!-- <p class="noDatap2">不要着急，要不再试试~</p> -->
            </div>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Table",
    props: {
        tableData: Array, // 数据来源
        // getData: {
        //     type: Function,
        //     default: null,
        // },
    },
    data() {
        return {
            header: [],
            terms_name: { 1: "年度", 2: "季度", 3: "月度" },
            dataList: [],
            dataListChild: [],
            unitList: [],
        };
    },
    created() {
        this.dataList = this.tableData;
    },
    watch: {
        tableData(newValue) {
            this.dataList = newValue;
        },
    },
    methods: {
        reDetail(item) {
            this.$router.push({ name: "ReportDetail", query: { id: item } });
        },
        // reload_index() {
        //     this.getData();
        //     console.log('aa');

        // },
        delete_one(item) {
            // reportDel
            this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    let reqObj = { id: item };
                    let res = await this.$reqApi.reportDel(reqObj);
                    this.loading = false;
                    console.log(res);
                    if (res == "删除成功") {
                        this.$message.success("删除成功");
                        // 通知父组建 刷新数据
                        // this.reload_index();
                    }
                    // this.getData();
                    this.$emit("reload_index");
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除！",
                    });
                });
        },
    },
};
</script>

<style scoped lang="less">
.noDatap1 {
    font-size: 20px;
    text-align: center;
}
.listUl {
    padding: 10px 0 20px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // flex-wrap wrap
    text-align: center;
}
.lisTitle {
    width: 100%;
    height: 36px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 8px 14px 0px rgba(33, 58, 233, 0.05);
    margin-bottom: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.titleNum {
    width: 13%;
}
.titleT {
    width: 40%;
}
.titleTime,
.titleType {
    width: 15%;
    position: relative;
    span {
        background-size: 7px;
        padding-right: 11px;
        box-sizing: border-box;
        line-height: 36px;
        cursor: pointer;
    }
    .timeFilterBox {
        width: 108px;
        background: #fff;
        padding: 12px 0;
        box-sizing: border-box;
        box-shadow: 0px 2px 8px 0px rgba(79, 101, 255, 0.24);
        border-radius: 4px;
        display: none;
        position: absolute;
        top: 26px;
        left: 50%;
        margin-left: -54px;
        font-weight: normal;
        line-height: 32px;
        transition: display 0.5s;
        div {
            cursor: pointer;
        }
        div:hover {
            color: #5c5d62;
        }
        .filterOn {
            background: #e9ecfe;
            color: #635ef9;
        }
    }
}

.titleTime:hover,
.titleType:hover {
    .timeFilterBox {
        display: block;
    }
}
.titleType {
    width: 15% !important;
}
.titleDo {
    width: 17%;
}
.listClass {
    width: 100%;
    height: 48px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 8px 14px 0px rgba(33, 58, 233, 0.05);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    transition: background-color 0.1s;
}
.listClass:hover {
    background-color: #d3d8f4;
}
.listItem {
    // padding 0 6px
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.listNum {
    width: 13%;
}
.listT {
    width: 40%;
    color: #2c2d33;
    cursor: pointer;
}
.listTime {
    width: 15%;
}
.listType {
    width: 15%;
    color: #5c5d62;
}
.listDo {
    width: 17%;
    color: #5c5d62;
    span {
        cursor: pointer;
    }
    .deleteIcon {
        margin-left: 20px;
        color: #8e9099;
    }
    span.seeDeIcon:hover {
        color: #635ef9;
        text-decoration: underline;
    }
    span.deleteIcon:hover {
        color: #635ef9;
    }
}
</style>

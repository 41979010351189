<template>
    <!-- <div class="content" v-loading.fullscreen="loading"> -->
    <div class="content">
        <div class="data_header">
            <div class="breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>{{ type_name }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="createBtn" v-show="type == 2 || type == 3" @click="createRe(type)">
                <img src="../../../assets/img/add.png" alt />
                <span>
                    {{
                    type == 2
                    ? '新建地区对比报告'
                    : type == 3
                    ? '新建时间对比报告'
                    : ''
                    }}
                </span>
            </div>
            <div class="search">
                <div class="filter_wrap">
                    <el-select
                        size="small"
                        class="sele"
                        v-model="nationid"
                        @change="goSearch"
                        placeholder="请选择"
                    >
                        <el-option label="缅甸" :value="2"></el-option>
                        <el-option label="老挝" :value="3"></el-option>
                    </el-select>
                </div>
                <input class="searchBox" placeholder="请输入需要检索的报告标题" v-model="keyword" />
                <div class="searchIcon" @click="goSearch"></div>
            </div>
        </div>
        <div class="data_wrap">
            <div class="reportContent">
                <div class="searchTip" v-show="searchModel">为您找到相关结果{{ total }}个</div>
                <div class="conBox">
                    <div
                        v-for="item in reports_list"
                        :key="item.id"
                        class="conItem"
                        v-show="!searchModel && type == 0"
                    >
                        <div class="conItem_title">
                            <el-badge value="NEW">
                                <p class="reporTypeTitle">{{ item.name }}</p>
                            </el-badge>
                            <div class="seeMoreBtn" @click="getMore(item.id)">查看更多 ></div>
                        </div>
                        <Mytable :tableData="item.data" @reload_index="getData" />
                    </div>
                    <div class="conItem" v-show="type != 0 || searchModel">
                        <Mytable :tableData="reports_list" @reload_index="getData" />
                    </div>
                </div>                             
            </div>
        </div>
        <el-dialog
            :title="createTitle"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            width="600px"
            class="createNewBox"
        >
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
                <el-form-item label="国家" prop="nationid">
                    <el-select
                        v-model="ruleForm.nationid"
                        placeholder="请选择报告类型"
                        @change="changeNation"
                    >
                        <el-option key="2" label="缅甸" :value="2">缅甸</el-option>
                        <el-option key="3" label="老挝" :value="3">老挝</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报告类型" prop="type">
                    <el-select v-model="ruleForm.type" placeholder="请选择报告类型" @change="changeType">
                        <el-option
                            v-for="item in types"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >{{ item.name }}</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间选择" prop="timeInterval">
                    <el-cascader
                        :disabled="ruleForm.type ? false : true"
                        v-model="ruleForm.timeInterval"
                        :options="timeoptions"
                        :props="{
                            expandTrigger: 'click',
                            label: 'name',
                            value: 'id',                           
                        }"
                        @change="timeChange"
                        ref="refHandle"
                    ></el-cascader>
                    <template v-if="ruleForm.createType==3">
                        <span style="margin:0 12px">至</span>
                        <el-cascader
                            :disabled="ruleForm.type?false:true"
                            v-model="ruleForm.timeInterval2"
                            :options="timeoptions"
                            :props="{ expandTrigger: 'click',label:'name',value:'id'}"
                            @change="timeChange2"
                            ref="refHandle"
                        ></el-cascader>
                        <p style="color:red;margin-left:100px">{{error1}}</p>
                    </template>
                </el-form-item>
                <el-form-item label="材料类型" prop="materialType">
                    <el-select
                        v-model="ruleForm.materialType"
                        multiple
                        collapse-tags
                        placeholder="请选择材料类型"
                    >
                        <!-- @change="changeMateri" -->
                        <el-option
                            v-for="item in cats"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >{{ item.name }}</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="对比地区" prop="compareRegion">
                    <el-select
                        v-model="ruleForm.compareRegion"
                        :multiple="multiChose"
                        collapse-tags
                        placeholder="请选择对比地区"
                    >
                        <el-option
                            v-for="item in regions"
                            :key="item.id"
                            :label="item.aname"
                            :value="item.id"
                        >{{ item.aname }}</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" class="newNow" @click="submitForm()">立即创建</el-button>
                    <el-button @click="close()">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import Mytable from "./Mytable";
export default {
    name: "Content",
    components: {
        Mytable,
    },
    props: {
        // 外部传递过来的国家
        nat: {
            default: 2,
            type: Number,
        },
        side_index: {
            default: 0,
            type: Number,
        }, // 侧边栏状态
    },
    data() {
        return {
            loading: false,
            dialogFormVisible: false,
            createType: 1, //1地区报告 2 时间报告
            createTitle: "",
            ruleForm: {
                nationid: 2,
            },
            rules: {
                nationid: [
                    {
                        required: true,
                        message: "请选择国家",
                        trigger: "change",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "请选择报告类型",
                        trigger: "change",
                    },
                ],
                timeInterval: [
                    {
                        required: true,
                        message: " ",
                        trigger: "",
                    },
                ],
                timeInterval2: [
                    {
                        required: true,
                        message: " ",
                        trigger: "",
                    },
                ],
                materialType: [
                    {
                        required: true,
                        message: "请选择材料类型",
                        trigger: "focus",
                    },
                ],
                compareRegion: [
                    {
                        required: true,
                        message: "请选择对比地区",
                        trigger: "focus",
                    },
                ],
            },
            nationid: 2, // 国家的ID
            type: 0, //报告类型
            terms_type: 0, //时间类型
            type_name: "",
            keyword: "",
            searchModel: 0,
            total: 0,
            multiChose: true,
            reports_list: [], //
            types: [], //可选择的数据类型
            timeoptions: [], //可选择的时间
            timevalue: "",
            timevalue2: "",
            regions: [], //地区
            cats: [], //材料
            error1: "",
        };
    },
    watch: {
        nat(e) {
            console.log("组件监听到变动", e);
            this.nationid = e;
        },
        side_index(e) {
            console.log("组件监听到变动", e);
            this.type = e;
            let arr = ["全部报告", "智能报告", "地区报告", "时间报告"];
            this.type_name = arr[e];           
            this.keyword = ""; //清空搜索
            this.searchModel = 0;
            this.getData();
            console.log("请求成功");
        },
        nationid(e) {
            // this.getData();
           sessionStorage.setItem("nationid", e);
        },
        timevalue() {
            if (this.timevalue2) {
                let time1 = new Date(this.timevalue2).getTime();
                let time2 = new Date(this.timevalue).getTime();
                if (time2 >= time1) {
                    this.error1 = "起始时间应小于结束时间";
                } else {
                    this.error1 = "";
                }
            }
        },
        timevalue2() {
            this.error1 = "";
            if (this.timevalue) {
                let time1 = new Date(this.timevalue2).getTime();
                let time2 = new Date(this.timevalue).getTime();
                if (time2 >= time1) {
                    this.error1 = "起始时间应小于结束时间";
                } else {
                    this.error1 = "";
                }
            }
        },
    },
    created() {
        let arr = ["全部报告", "智能报告", "地区报告", "时间报告"];
        this.type = this.side_index;
        this.type_name = arr[this.type];
        let nat=sessionStorage.getItem("nationid");
        this.nationid = Number(nat);
        console.log(this.nationid);
        
        this.getData();
    },
    methods: {
        async getData() {
            let res,
                reqObj = {
                    nationid: this.nationid, // 国家的ID
                    type: this.type, // 数据类型 0全部 1智能报告
                    terms_type: this.terms_type, //时间类型 1年度 2季度 3月度
                    keyword: this.keyword, //搜索的关键词
                };

            res = await this.$reqApi.getReportList(reqObj);
            if (this.type > 0 || this.keyword) {
                this.searchModel = res.searchModel; //表格数据
                this.total = res.total; //表格数据
                this.reports_list = res.data; //表格数据
            } else {
                this.reports_list = res; //表格数据
            }

            this.loading = false;
        },
        handleTimeFilter(item) {
            // 年度、季度、月度数据切换
            this.currentTimeFilter = item;
            this.initOptions();
            this.getData();
        },
        handleDataFilter(index) {
            // 指数、同比、环比、价格切换
            this.currentTypeFilter = index;
            this.getData();
        },
        handleMoneyFilter(index) {
            this.currentMoneyIndex = index;
            this.getData();
        },
        searContent() {
            // 显示图表
            // this.$refs.CatTable.openChart();
        },
        goSearch() {
            // 显示图表
            this.getData();
        },
        getMore(index) {
            // 通知父组建 侧栏变化
            this.$emit("changeSideItem", index);
        },
        createRe(item) {
            if (item == 2) {
                this.createTitle = "新建地区对比报告";
            }
            if (item == 3) {
                this.createTitle = "新建时间对比报告";
            }
            this.createType = item;
            if (this.createType == 3) {
                this.multiChose = false;
            }
            //请求addBefore
            this.changeNation();
            this.loading = false;
            this.dialogFormVisible = true;
        },
        async changeNation() {
            let reqObj = {
                nationid: this.ruleForm.nationid,
                createType: this.createType,
            };
            this.nationid = this.ruleForm.nationid;
            this.loading = true;
            let res = await this.$reqApi.getAddBefore(reqObj);
            this.types = res.termsTypeData;
            this.regions = res.areasData;
            this.cats = res.categoryData;
            this.loading = false;
            this.ruleForm = {
                nationid: this.ruleForm.nationid,
                createType: this.createType,
            };
        },
        changeType(vv) {
            this.types.map((item) => {
                if (item.id == vv) {
                    this.timeoptions = item.data;
                }
            });
        },
        timeChange(val) {
            if (!val) {
                return false;
            }
            // this.$refs.refHandle.dropDownVisible = false
            if (this.ruleForm.type == 1) {
                this.timevalue = val[0] + "-01-01";
            } else if (this.ruleForm.type == 2) {
                this.timevalue = val[0] + "-" + val[1] + "-01";
            } else {
                this.timevalue = val[0] + "-" + val[1] + "-01";
            }
        },
        timeChange2(val) {
            if (!val) {
                return false;
            }
            // this.$refs.refHandle.dropDownVisible = false
            if (this.ruleForm.type == 1) {
                this.timevalue = val[0] + "-01-01";
            } else if (this.ruleForm.type == 2) {
                this.timevalue2 = val[0] + "-" + val[1] + "-01";
            } else {
                this.timevalue2 = val[0] + "-" + val[1] + "-01";
            }
        },
        close() {
            this.dialogFormVisible = false;
            this.ruleForm = {
                nationid: 2,
            };
        },
        async submitForm() {
            console.log(this.ruleForm);
            this.ruleForm.timeInterval;
            this.loading = true;
            let res = await this.$reqApi.addData(this.ruleForm);
            this.loading = false;
            if (res == "创建成功") {
                this.$message.success("创建成功");
                this.close();
                this.getData();
            }
            console.log(res);
        },
    },
};
</script>

<style scoped lang="less">
.el-cascader {
    width: 160px;
}

.data_header {
    display: flex;
}

.search {
    width: 460px;
    height: 38px;
    background-color: #fff;
    border-radius: 8px;
    margin: 10px 0;
    margin-left: 156px;
    display: flex;
}

.searchBox {
    width: calc(100% - 58px);
    height: 38px;
    border: none;
    border: none;
    border-radius: 8px 0 0 8px;
    text-indent: 15px;
}

.searchIcon {
    width: 58px;
    height: 38px;
    border-radius: 0px 8px 8px 0px;
    background: #ff7437 url(../../../assets/img/search.png) no-repeat center;
    cursor: pointer; //sideNavIcon-2.png
}

.searchIcon:hover {
    background-color: #fe9b78;
}

.breadcrumb {
    background: #fff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    border-radius: 8px;
    min-width: 158px;
    box-shadow: 0 8px 14px 0 rgba(33, 58, 233, 0.05);
    height: 38px;
    margin: 10px 0;

    &:before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #7f94ff;
        border-radius: 8px;
        margin-right: 8px;
    }

    .el-breadcrumb {
        .el-breadcrumb__item {
            &:last-child {
                /deep/ .el-breadcrumb__inner {
                    cursor: pointer !important;
                    color: #333;
                    font-weight: bold;
                }
            }
        }
    }
}

.reportContent {
    width: 100%;
    padding-right: 20px;
    box-sizing: border-box;
    // 这里声明了主要内容的字体大小
    font-size: 12px;
    color: #8e9099;
    line-height: 14px;
}

.conItem_title {
    display: flex;
    align-items: center;
}

.reporTypeTitle {
    font-size: 20px;
    color: #000;
    line-height: 20px;
}

.seeMoreBtn {
    width: 82px;
    height: 20px;
    background: #6389fb;
    border-radius: 10px;
    margin-left: 60px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    line-height: 20px;
    text-align: center;
}

.seeMoreBtn:hover {
    background: #7f94ff;
}

.filter_wrap {
    box-shadow: 0 8px 14px 0 rgba(33, 58, 233, 0.05);
    border-radius: 8px;
    padding-right: 18px;
    display: flex;
    align-items: center;

    h1 {
        font-size: 20px;
        color: #6064fd;
    }

    ul {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            color: #8e9099;
            border: 1px solid #d6d9e2;
            text-align: center;
            cursor: pointer;
            font-size: 12px;
            padding: 8px 16px;
            margin-left: -1px;

            &.active {
                background: #6064fd;
                border: 1px solid #7f94ff;
                color: #fff;
            }
        }
    }

    .sele {
        width: 100px;
        margin-left: 20px;
    }

    .btn {
        margin-left: 20px;
    }

    .el-button.is-round {
        background: #7f94ff;
        padding: 8px 20px;
        border-color: #7f94ff;
    }

    .el-button.is-round:hover {
        background: #6064fd;
        border-color: #6064fd;
    }
}

.data_wrap {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin: 20px 0;
    padding: 20px;

    .data_header {
        font-size: 20px;
        height: 56px;
        padding: 0 40px;
        background: #6064fd;
        border-radius: 4px 4px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;

        .left {
            display: flex;
            align-items: center;

            h1 i {
                color: #7bfaff;
                font-weight: normal;
                padding: 0 6px;
            }

            .data_filter {
                display: flex;
                font-size: 14px;
                color: #bdbef8;
                cursor: pointer;
                line-height: 1;
                align-items: center;
                margin-left: 60px;

                li {
                    margin-left: 20px;

                    &.active {
                        font-size: 18px;
                        color: #fff;
                    }
                }
            }

            .price_filter {
                display: flex;
                align-items: center;
                font-size: 12px;

                ul {
                    display: flex;
                    margin: 0 6px 0 26px;

                    li {
                        color: #fff;
                        border: 1px solid #d6d9e2;
                        text-align: center;
                        cursor: pointer;
                        font-size: 12px;
                        padding: 4px 16px;
                        margin-left: -1px;

                        &.active {
                            color: #6064fd;
                            background: #fff;
                        }
                    }
                }
            }

            .tips {
                color: #fefefe;
                font-size: 12px;
                margin-left: 40px;
                cursor: pointer;

                img {
                    margin-left: 4px;
                }
            }
        }

        .right {
            font-size: 14px;
            flex-shrink: 0;

            .el-select {
                width: 130px;
                margin-left: 6px;
            }

            /deep/ .el-input__inner,
            /deep/ .el-input__icon {
                height: 34px;
                line-height: 34px;
            }
        }
    }
}

.createBtn {
    min-width: 154px;
    height: 24px;
    background: #8b78fe;
    padding: 0 10px;
    border: 1px #6c56f5 solid;
    box-sizing: border-box;
    border-radius: 12px;
    margin-left: 30px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 15px;
}

.createBtn:hover {
    background: #a99bff;
}
</style>
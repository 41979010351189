<template>
    <el-container class="report_wrap">
        <el-header>
            <ReportHeader meauIndex="2" />
        </el-header>
        <el-container>
            <el-aside width="200px">
                <div class="side_wrap">
                    <h1 :class="{'active':isActive(0)}" @click="handleTitle(0)">
                        <span class="iconfont">&#xe608;</span>全部报告
                    </h1>
                    <h1 :class="{'active':isActive(1)}" @click="handleTitle(1)">
                        <span class="iconfont">&#xe60b;</span>智能报告
                    </h1>
                    <h1 :class="{'active':isActive(2)}" @click="handleTitle(2)">
                        <span class="iconfont">&#xe60d;</span>地区报告
                    </h1>
                    <h1 :class="{'active':isActive(3)}" @click="handleTitle(3)">
                        <span class="iconfont">&#xe60a;</span>
                        时间报告
                    </h1>
                </div>            
            </el-aside>
            <el-container>
                <el-main>
                    <All :side_index="side_index" :nat="nationid" @changeSideItem="changeSideItem" />
                </el-main>
                <el-footer>
                    <CopyRightFooter />
                </el-footer>
            </el-container>
        </el-container>
    </el-container>
</template>

<script>
import ReportHeader from "../components/Report/ReportHeader";
import CopyRightFooter from "../components/Report/CopyRightFooter";
import All from "../components/Report/ReportSmart/all";
export default {
    name: "report",
    components: {
        ReportHeader,
        CopyRightFooter,
        All,
    },
    data() {
        return {
            nationid: 2, // 默认国家
            side_index: 0,
            showIndex: 0,
            treeData: {},
        };
    },
    created() {
        let type=Number(this.$route.query.type);
        if (type) {
            this.changeSideItem(type);
        }
        
        // 加载状态管理中的默认国家
        this.nationid = this.$store.state.nationid;
        console.log("默认国家", this.nationid);

        // this.getBySide();
    },
    methods: {
        changeSideItem(item) {
            // 保存点击侧边栏树形菜单点击项
            this.side_index = item;
            this.showIndex = item;           
        },
        isActive(index) {
            if (index == this.showIndex) {
                return true;
            } else {
                return false;
            }
        },
        handleTitle(index) {
            if (this.showIndex == index) return;
            this.showIndex = index;
            this.side_index = index;
        },
    },
};
</script>
<style>
.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}
</style>
<style lang="less" scoped>
.side_wrap {
    h1 {
        width: 100%;
        height: 58px;
        padding: 0 18px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin-bottom: 10px;
        color: #8e9099;
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        &.active {
            background: linear-gradient(270deg, #61e0ff, #6439f8) !important;
            color: #fff;
            &:before {
                background-position: 0 -76px;
            }
            i {
                transform: rotate(90deg);
            }
        }
        span.iconfont {
            margin-right: 8px;
            font-size: 20px;
            font-weight: 400;
        }
    }
    /deep/ .el-tree-node.is-current > .el-tree-node__content {
        background: #7f94ff !important;
        color: #fff;
    }
    /deep/ .is-current > .el-tree-node__content * {
        color: #fff !important;
    }
    /deep/ .el-icon-caret-right {
        font-family: iconfont !important;
    }
    /deep/ .el-tree-node__content .el-icon-caret-right:before {
        content: "\e620";
    }
    /deep/ .el-tree-node__children .el-icon-caret-right:before {
        content: "";
    }
    /deep/ .el-tree-node__expand-icon {
        color: #637CFB;
    }
    /deep/ .el-tree-node__label {
        color: #2C2D33;
    }
    /deep/ .el-tree-node__content {
        padding: 0 13px !important;
        height: 30px;
    }
}
.report_wrap {
    min-height: 100%;
    min-width: 1440px;
    .el-header {
        box-shadow: 0 8px 14px 0 rgba(33, 58, 233, 0.1);
        height: auto !important;
        padding: 0;
        margin-bottom: 8px;
    }
    .el-container {
        background-color: #f6f7fe;
        .el-aside {
            min-height: 100%;
            box-shadow: 0 8px 14px 0 rgba(33, 58, 233, 0.05);
            background: #fff;
            width: 270px !important;
            margin-top: -8px;
        }
        .el-footer {
            height: auto !important;
        }
    }
}
</style>
